import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Img } from 'react-image';

const BrowserViewer = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2>Browser Viewer</h2>
                    </Col>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        An application to view multiple browser windows at once. You can set the size of the windows, how zoomed in they are, and the starting URL. The application will save all the windows currently in use, and also save the scroll position, so you can return to exactly where you left off. You can download it <a href="https://www.mediafire.com/file/zdnmz7r1b6o0wpl/Browser_Viewer.msi/file">here</a>
                        <br />
                        <br />
                        <br />
                        <Img src={require('../../../images/stock_viewer2.png')} className="img-responsive" width="100%" alt="team img 1" loader={<Spinner />} />

                        <Img src={require('../../../images/demo.gif')} className="img-responsive" width="100%" alt="team img 1" loader={<Spinner />} />

                    </Col>
                </Row>                         
            </Container>
        </section>
    </div>
);

export default connect()(BrowserViewer);
