import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Footer.css';

export default class Footer extends React.Component {
    public render() {
        
        return (
            <div id="footer">
                <footer id="copyright">
                    <Container>
                        <Row>
                            <Col md="12" className="text-center">
                                <p className="wow bounceIn" data-wow-offset="50" data-wow-delay="0.3s">
                                    Copyright &copy; 2022 Brett Hewitt</p>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        );
    }
}