import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import './projects.css';

const Projects = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2>Current Projects</h2>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        <Link to='/browser-viewer'><h3>Browser Viewer</h3></Link>
                        Software to view multiple browser windows at once, with the capability to save each window url and current scroll position of the window.
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        <Link to='/chrome-control'><h3>Chrome Control</h3></Link>
                        A Google Chrome Extension with a native messaging host, that allows you to control Chrome using C#.
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        <Link to='/nas'><h3>RGB Light Sync</h3></Link>
                        An open source desktop application to sync your RGB lighting. Currently supports Alienware, Razer and Phillips Hue Bridge.
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        <Link to='/sky-control'><h3>Sky Q Box Control</h3></Link>
                        An open source library to control your Sky Q box over the network using C#.
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        <Link to='/sony-bravia-control'><h3>Sony Bravia TV Control</h3></Link>
                        An open source library to control your Sony Bravia TV over the network using C#.
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        <Link to='/monitor-info'><h3>Monitor Information</h3></Link>
                        An open source library to retrieve detailed information about connected monitors.
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        <Link to='/audio-devices'><h3>Default Audio Playback Device</h3></Link>
                        An open source library to get detailed information on your audio devices and change your default audio playback device using C#.
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
);

export default connect()(Projects);
