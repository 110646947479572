import * as React from 'react';
import Header from './Header';
import Footer from './Footer';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <Header/>
        {props.children}
        <Footer/>
    </React.Fragment>
);
