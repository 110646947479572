import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Img } from 'react-image';

const ChromeControl = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2>Chrome Control</h2>
                    </Col>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        A Google Chrome Extension that can receive commands from C#. To use this, you must install the extension and native messaging <a href="https://www.mediafire.com/file/lymxzlfjw25giwd/dataDyne_Chrome_Native_Messaging_Server.msi/file">here</a>. You can then install the ChromeControl <a href="https://www.nuget.org/packages/BrettHewitt.ChromeControl/1.0.0">nuget</a> package into your application and control Chrome.
                    </Col>
                </Row>                         
            </Container>
        </section>
    </div>
);

export default connect()(ChromeControl);
