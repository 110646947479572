import * as React from 'react';
import './Footer.css';

interface IState {
    text: string,
    isDeleting: boolean,
    loopNum: number,
    typingSpeed: number
}

interface IProps {
    displayText: Array<string>,
    typeSpeed: number,
    deleteSpeed: number
}

export default class TypedEffect extends React.PureComponent<IProps, IState> {

    timeoutVar: any;

    constructor(props : IProps) {
        super(props);

        this.state = {
            text: '',
            isDeleting: false,
            loopNum: 0,
            typingSpeed: 0
        }
    }

    public static defaultProps = {
        displayText: [],
        typeSpeed: 0,
        deleteSpeed: 0
    }

    public componentDidMount() {
        this.handleType();
    }

    public componentWillUnmount() {
        if (this.timeoutVar) {
            clearTimeout(this.timeoutVar);
        }
    }

    private handleType = () => {
        const { displayText, typeSpeed, deleteSpeed } = this.props;
        const { isDeleting, loopNum, text, typingSpeed } = this.state;
        const i = loopNum % displayText.length;
        const fullText = displayText[i];

        this.setState({
            text: isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1),
            typingSpeed: isDeleting ? deleteSpeed : typeSpeed
        });

        var humanize = Math.round(Math.random() * (70)) + typingSpeed;

        if (!isDeleting && text === fullText) {
            this.setState({ isDeleting: true });
            this.timeoutVar = setTimeout(this.handleType, 500);
            return;
        } else if (isDeleting && text === '') {

            this.setState({
                isDeleting: false,
                loopNum: loopNum + 1
            });

        }

        this.timeoutVar = setTimeout(this.handleType, humanize);
    };

    public render() {        
        return (
            <span>{this.state.text}</span>
        );
    }
}