import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

const Mwa = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2 className="wow bounceIn" data-wow-offset="50" data-wow-delay="0.3s">Manual Whisker Annotator</h2>
                    </Col>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv" className="wow fadeInLeft" data-wow-offset="50" data-wow-delay="0.9s">
                        Manual Whisker Annotator is a new tracking program specifically designed for tracking whiskers. It is quick, intuitive, and comes with a wide array of features including :

                        <ul>
                            <li>Track head location and orientation </li>
                            <li>Model whiskers using either 2, 3 or 4 points per whisker (linear, quadratic or cubic)</li>
                            <li>Add generic points</li>
                            <li>Export data to Excel in a readable and intuitive format</li>
                            <li>Analyse data with the in built statistics suite</li>
                            <li>Image equalisation to see even the faintest details</li>
                        </ul>

                        <br />
                        <br />
                        Manual Whisker Annotator was developed at Manchester Metropolitan University by Dr. Brett Hewitt (brett@bretthewitt.net), Dr. Robyn Grant (Robyn.Grant@mmu.ac.uk) and Dr. Moi Hoon Yap (M.Yap@mmu.ac.uk)

                        <br />
                        <br />
                        You can download the latest version <a href="https://www.mediafire.com/file/j1gioq2wlyatq2f/MWA-Setup_1.1.exe/file">here</a>
                        <br />
                        Sample Data: <a href="http://www.mediafire.com/download/7sd7glm0paza8o5/140704-02-Frames200-400.mwa">140704-02 Data</a>
                        <br />
                        Sample Video: <a href="http://www.mediafire.com/download/c79tl9ml7kiizfy/02.avi">140704-02 Video</a>
                        <br />
                        <br />
                        MWA documentation can be downloaded <a href="mwa-documentation.pdf">here</a>
                        <br />
                        <br />
                    </Col>
                </Row>                         
            </Container>
        </section>
    </div>
);

export default connect()(Mwa);
