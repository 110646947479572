import * as React from 'react';
import './NavMenu.css';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    Container
} from 'reactstrap';

interface IState {
    homeCurrent: boolean,
    homeHeight: number,
    aboutCurrent: boolean,
    aboutHeight: number,
    serviceCurrent: boolean,
    serviceHeight: number,
    portfolioCurrent: boolean,
    portfolioHeight: number,
    contactCurrent: boolean,
    contactHeight: number,
    isOpen: boolean
}

export default class NavMenu extends React.PureComponent<{}, IState> {
    public state = {
        homeCurrent: true,
        homeHeight: 0,
        aboutCurrent: false,
        aboutHeight: 0,
        serviceCurrent: false,
        serviceHeight: 0,
        portfolioCurrent: false,
        portfolioHeight: 0,
        contactCurrent: false,
        contactHeight: 0,
        isOpen: false
    };

    constructor(props: {}) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.calculateHeights = this.calculateHeights.bind(this);
    }

    public componentDidMount() {
        this.calculateHeights();

        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.calculateHeights);
    }

    private calculateHeights() {
        const winScroll = document.documentElement.scrollTop;

        const aboutHeightRect = document.getElementById("about")!.getBoundingClientRect();
        const aboutHeight = aboutHeightRect.top + winScroll;
        const serviceHeightRect = document.getElementById("service")!.getBoundingClientRect();
        const serviceHeight = serviceHeightRect.top + winScroll;
        const portfolioHeightRect = document.getElementById("portfolio")!.getBoundingClientRect();
        const portfolioHeight = portfolioHeightRect.top + winScroll;
        const contactHeightRect = document.getElementById("contact")!.getBoundingClientRect();
        const contactHeight = contactHeightRect.top + winScroll;

        this.setState({
            aboutHeight: aboutHeight,
            serviceHeight: serviceHeight,
            portfolioHeight: portfolioHeight,
            contactHeight: contactHeight
        });
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.calculateHeights);
    }

    private handleScroll = () => {
        this.handleSectionInView();
    }

    private handleSectionInView() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        const currentHeight = winScroll;

        if (currentHeight > this.state.contactHeight) {
            this.setState({
                homeCurrent: false,
                aboutCurrent: false,
                serviceCurrent: false,
                portfolioCurrent: false,
                contactCurrent: true
            });
            return;
        }

        if (currentHeight > this.state.portfolioHeight) {
            this.setState({
                homeCurrent: false,
                aboutCurrent: false,
                serviceCurrent: false,
                portfolioCurrent: true,
                contactCurrent: false
            });
            return;
        }

        if (currentHeight > this.state.serviceHeight) {
            this.setState({
                homeCurrent: false,
                aboutCurrent: false,
                serviceCurrent: true,
                portfolioCurrent: false,
                contactCurrent: false
            });
            return;
        }

        if (currentHeight > this.state.aboutHeight) {
            this.setState({
                homeCurrent: false,
                aboutCurrent: true,
                serviceCurrent: false,
                portfolioCurrent: false,
                contactCurrent: false
            });
            return;
        }

        this.setState({
            homeCurrent: true,
            aboutCurrent: false,
            serviceCurrent: false,
            portfolioCurrent: false,
            contactCurrent: false
        });
    }

    private toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    public render() {
        return (
            
            <Navbar sticky="top" id="nav-container" color="light" light expand="md" className="templatemo-nav">
                <Container>
                    <NavbarToggler className="ml-auto" onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink className={`${this.state.homeCurrent ? "current" : ""}`} href="#home">HOME</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={`${this.state.aboutCurrent ? "current" : ""}`} href="#about">ABOUT</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={`${this.state.serviceCurrent ? "current" : ""}`} href="#service">SERVICE</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={`${this.state.portfolioCurrent ? "current" : ""}`} href="#portfolio">PORTFOLIO</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={`${this.state.contactCurrent ? "current" : ""}`} href="#contact">CONTACT</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
            
        );
    }
}
