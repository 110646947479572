import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

const AudioInfo = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2>Audio Device Information</h2>
                    </Col>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv" className="wow fadeInLeft" data-wow-offset="50" data-wow-delay="0.9s">
                        Obtain information about available audio playback devices and switch which one is currently in use. It is available on Nuget and you can view the source code <a href="https://github.com/BrettHewitt/AudioPlaybackDevices">here</a>
                    </Col>
                </Row>                         
            </Container>
        </section>
    </div>
);

export default connect()(AudioInfo);
