import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Img } from 'react-image';

const Terms = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2>Browser Viewer Pro License Terms</h2>
                    </Col>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        <ul>
                            <li>The license is valid only for the machine it was purchased on. If you change or upgrade your hardware, it may invalidate your license.</li>
                            <li>The owner is responsible for keeping a copy of their license safe. Lost licenses will need to be repurhcased.</li>
                            <li>No refunds will be given for any reason.</li>
                            <li>These terms do not alter the original terms and conditions agreed upon whe installing the product.</li>
                        </ul>
                    </Col>
                </Row>                         
            </Container>
        </section>
    </div>
);

export default connect()(Terms);
