import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Img } from 'react-image';

const Nas = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2>Nommo Alienware Sync</h2>
                    </Col>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        The Nommo Chroma and Alienware Monitor Sync software allows you to sync your lights between devices. You can choose between cycling through the spectrum, using the average colour of a portion of the screen, or defining any number of custom colours to cycle through. You can download the software <a href="https://www.mediafire.com/file/2hw44c670qzzxty/ddNAS-1.0.0.5.zip/file">here</a>
                        <br />
                        <Img src={require('../../../images/pages/nas/status.png')} className="img-responsive" loader={<Spinner />} />
                        <br />
                        <br />
                        <br />
                        <Img src={require('../../../images/pages/nas/lighting1.png')} className="img-responsive" loader={<Spinner />} />
                        <br />
                        <br />
                        <br />
                        <Img src={require('../../../images/pages/nas/lighting2.png')} className="img-responsive" loader={<Spinner />} />
                        <br />
                        <br />
                        <br />
                        <Img src={require('../../../images/pages/nas/lighting3.png')} className="img-responsive" loader={<Spinner />} />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>                         
            </Container>
        </section>
    </div>
);

export default connect()(Nas);
