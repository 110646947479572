import * as React from 'react';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import './Portfolio.css';
import { Link } from 'react-router-dom';

interface IState {
    showModalCloud: boolean,
    showModalMicroservices: boolean,
    showModalDeepLearning: boolean,
    showModalArchitecture: boolean,
    showModal3D: boolean,
    showModalAuth: boolean,
    showModalSecurity: boolean,
    showModalAutomation: boolean
}

export default class Portfolio extends React.Component<{}, IState> {
    public state = {
        showModalCloud: false,
        showModalMicroservices: false,
        showModalDeepLearning: false,
        showModalArchitecture: false,
        showModal3D: false,
        showModalAuth: false,
        showModalSecurity: false,
        showModalAutomation: false
    };

    constructor(props: any) {
        super(props);

        this.toggleModalCloud = this.toggleModalCloud.bind(this);
        this.toggleModalMicroservices = this.toggleModalMicroservices.bind(this);
        this.toggleModalML = this.toggleModalML.bind(this);
        this.toggleModalArchitecture = this.toggleModalArchitecture.bind(this);
        this.toggleModal3D = this.toggleModal3D.bind(this);
        this.toggleModalAuth = this.toggleModalAuth.bind(this);
        this.toggleModalSecurity = this.toggleModalSecurity.bind(this);
        this.toggleModalAutomation = this.toggleModalAutomation.bind(this);
    }

    toggleModalCloud() {
        this.setState({
            showModalCloud: !this.state.showModalCloud
        });
    }

    toggleModalMicroservices() {
        this.setState({
            showModalMicroservices: !this.state.showModalMicroservices
        });
    }

    toggleModalML() {
        this.setState({
            showModalDeepLearning: !this.state.showModalDeepLearning
        });
    }

    toggleModalArchitecture() {
        this.setState({
            showModalArchitecture: !this.state.showModalArchitecture
        });
    }

    toggleModal3D() {
        this.setState({
            showModal3D: !this.state.showModal3D
        });
    }

    toggleModalAuth() {
        this.setState({
            showModalAuth: !this.state.showModalAuth
        });
    }

    toggleModalSecurity() {
        this.setState({
            showModalSecurity: !this.state.showModalSecurity
        });
    }

    toggleModalAutomation() {
        this.setState({
            showModalAutomation: !this.state.showModalAutomation
        });
    }

    renderCloudModal() {
        return (
            <Modal isOpen={this.state.showModalCloud} toggle={this.toggleModalCloud}>
                <ModalHeader>I've got my head in the Clouds</ModalHeader>
                <ModalBody>
                    I've built a multitude of systems that leverage the incredible flexibility of the major cloud based platforms Azure and AWS. Whether it's a website, severless computing, machine learning, or a complex microservice based architecture, cloud computing allows you to build scalable solutions.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleModalCloud}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderMicroservicesModal() {
        return (
            <Modal isOpen={this.state.showModalMicroservices} toggle={this.toggleModalMicroservices}>
                <ModalHeader>Microservices</ModalHeader>
                <ModalBody>
                    For complex software, nothing beats a mircoservice architecture, allowing you to build service specific infrastructure to meet even the most performant demands. Seperating teams areas of concerns also provides enormous benefit. Gone are the days of having to be careful about impacting a completely seperate area of the product with your code changes, microservices decoupled nature can provide enormous benefits.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleModalMicroservices}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderMLModal() {
        return (
            <Modal isOpen={this.state.showModalDeepLearning} toggle={this.toggleModalML}>
                <ModalHeader>Deep Learning</ModalHeader>
                <ModalBody>
                    Deep learning has ushered in a new era of data analytics, whether it's helping a computer make sense of an image, or predicting an event, there's no denying Deep Learning has had a huge impact. But even if you've created the best Deep Learning model for your use case, deploying this into an application can still be challenging. I've got experience helping companies generate datasets, re-train models on the fly, through to deploying these models for use locally or from the cloud.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleModalML}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderArchitectureModal() {
        return (
            <Modal isOpen={this.state.showModalArchitecture} toggle={this.toggleModalArchitecture}>
                <ModalHeader>Software Architecture</ModalHeader>
                <ModalBody>
                    For any software, getting the architecture correct from the beginning can save countless headaches. It's not always obvious what the correct solution should be, infrastrcture and business constraints can often prevent the use of the most ideal architecture. The experience I've gained from building small scale desktop application, to enterprise leading cloud based microservices has given me the insight needed to make the correct decisions on how an application should be best built.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleModalArchitecture}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }

    render3DModal() {
        return (
            <Modal isOpen={this.state.showModal3D} toggle={this.toggleModal3D}>
                <ModalHeader>3D Visualisations</ModalHeader>
                <ModalBody>
                    Custom built software can be more than just screens of text. I've built real time 3D visualisations of labopratory workflows, through to rendering nuclear interactions. If you're after a bespoke piece of software where how it looks is just as important as how it works, my experience with Unity 3D can help you realise that vision.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleModal3D}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderAuthModal() {
        return (
            <Modal isOpen={this.state.showModalAuth} toggle={this.toggleModalAuth}>
                <ModalHeader>Authentication and Authorization</ModalHeader>
                <ModalBody>
                    The vast majority of systems will require authentication and authorization, but more and more companies are relying on 3rd parties to provide this functionality and for good reason. Having to store usernames and passwords yourself, as well as managing password policies can be cumbersome, and in some cases, dangerous. If you're infrastructure and hashing algorithms aren't strong enough, you always run the risk of a databrech and losing sensitive data. Outsourcing this not only provides greater security, but often financial benefit for not having to maintain the systems required. Integrating this into your software needs though is not straight forward. I've created authentication systems ranging from the standard username and password and Active Directory, through to federated gateways that allow multiple Single Sign On Identity Providers to be used.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleModalAuth}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderCyberModal() {
        return (
            <Modal isOpen={this.state.showModalSecurity} toggle={this.toggleModalSecurity}>
                <ModalHeader>Cyber Security</ModalHeader>
                <ModalBody>
                    Nothing can be more damaging for a company than a data breach, the financial and public impact can be devastating. It is therefore critical that software is not only written to a high security standpoint, but the underlying infrastrcture is also regularly maintained and updated. Getting your software tested for security by an external security is a fantastic way to stay up to date, but it's far better to write, and deploy software that is secure from the start.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleModalSecurity}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderAutomationModal() {
        return (
            <Modal isOpen={this.state.showModalAutomation} toggle={this.toggleModalAutomation}>
                <ModalHeader>Automation</ModalHeader>
                <ModalBody>
                    Automation brings huge benefits when deployed correctly. While many people think of automation as robots performing complex tasks, the true power of automation lies in removing the mundane and time consuming tasks that humans waste time on, freeing up their time to do something much more beneficial. Automation can range from automatically inputting data from an email into another bit of software, through to automating laboratory equipment allowing labs to run 24/7. I've helped create both.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggleModalAutomation}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }

    public render() {        
        return (
            <section id="portfolio">
                <Container>
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="wow bounceIn" data-wow-offset="50" data-wow-delay="0.3s">MY <span>PORTFOLIO</span></h2>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="portfolio-thumb">
                                <img src={require('../images/cloudcomputing.jpg')} className="img-responsive" alt="portfolio img 1" />
                                <div className="portfolio-overlay" onClick={this.toggleModalCloud}>
                                    <h4>Cloud computing</h4>
                                    <p></p>
                                    <a className="btn btn-default pbutton">DETAIL</a>
                                    {this.renderCloudModal()}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="portfolio-thumb">
                                <img src={require("../images/microservices.jpg")} className="img-responsive" alt="portfolio img 2" />
                                <div className="portfolio-overlay" onClick={this.toggleModalMicroservices}>
                                    <h4>Microservices</h4>
                                    <p></p>
                                    <a className="btn btn-default pbutton"> DETAIL</a>
                                    {this.renderMicroservicesModal()}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="portfolio-thumb">
                                <img src={require("../images/deeplearning.jpg")} className="img-responsive" alt="portfolio img 2" />
                                <div className="portfolio-overlay" onClick={this.toggleModalML}>
                                    <h4>Deep Learning</h4>
                                    <p></p>
                                    <a className="btn btn-default pbutton">DETAIL</a>
                                    {this.renderMLModal()}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="portfolio-thumb">
                                <img src={require("../images/softwarearchitecture.jpg")} className="img-responsive" alt="portfolio img 3" />
                                <div className="portfolio-overlay" onClick={this.toggleModalArchitecture}>
                                    <h4>Architecture</h4>
                                    <p></p>
                                    <a className="btn btn-default pbutton">DETAIL</a>
                                    {this.renderArchitectureModal()}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="portfolio-thumb">
                                <img src={require("../images/lab.jpg")} className="img-responsive" alt="portfolio img 4" />
                                <div className="portfolio-overlay" onClick={this.toggleModal3D}>
                                    <h4>3D Visualisations</h4>
                                    <p></p>
                                    <a className="btn btn-default pbutton">DETAIL</a>
                                    {this.render3DModal()}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="portfolio-thumb">
                                <img src={require("../images/authentication-and-authorization.jpg")} className="img-responsive" alt="portfolio img 4" />
                                <div className="portfolio-overlay" onClick={this.toggleModalAuth}>
                                    <h4>Authentication and Authorization</h4>
                                    <p></p>
                                    <a className="btn btn-default pbutton">DETAIL</a>
                                    {this.renderAuthModal()}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="portfolio-thumb">
                                <img src={require("../images/Security.jpg")} className="img-responsive" alt="portfolio img 1" />
                                <div className="portfolio-overlay" onClick={this.toggleModalSecurity}>
                                    <h4>Cyber Security</h4>
                                    <p></p>
                                    <a className="btn btn-default pbutton">DETAIL</a>
                                    {this.renderCyberModal()}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="portfolio-thumb">
                                <img src={require("../images/automation.jpg")} className="img-responsive" alt="portfolio img 3" />
                                <div className="portfolio-overlay" onClick={this.toggleModalAutomation}>
                                    <h4>Automation</h4>
                                    <p></p>
                                    <a className="btn btn-default pbutton">DETAILs</a>
                                    {this.renderAutomationModal()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row className="justify-content-md-center">
                        <Col md="auto" style={{ margin: 10 }}>
                            <Link to='/projects'><h3>VIEW CURRENT PROJECTS</h3></Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}