import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

const SkyControl = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2>Sky Control</h2>
                    </Col>
                    <Col md={{ size: 10, offset: 1 }} sm={{ size: 10, offset: 1 }} xs="12" id="MainDiv">
                        C# library to control your Sky Q box over the local network. You can view the source code <a href="https://github.com/BrettHewitt/SkyQBoxControl">here</a>
                    </Col>
                </Row>                         
            </Container>
        </section>
    </div>
);

export default connect()(SkyControl);
