import * as React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop, faEye, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Spinner } from 'reactstrap';
import TypedEffect from './TypedEffect';
import ContactMe from './ContactMe';
import Portfolio from './Portfolio';
import NavMenu from './NavMenu';
import { Img } from 'react-image';

const Home = () => (
    <div>
        <NavMenu />
        <div className="cc">
            <section id="home">
                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h1 className="wow fadeIn" data-wow-offset="50" data-wow-delay="0.9s">Dr Brett Hewitt<br /><span>Software Consultant</span></h1>
                            <div className="element">
                                <TypedEffect typeSpeed={34} deleteSpeed={4} displayText={[".NET Solutions", "Image Processing", "Computer Vision", "Deep Learning"]} />
                            </div>
                            <a data-scroll href="#about" className="btn btn-default wow fadeInUp" data-wow-offset="50" data-wow-delay="0.6s">About</a>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="about">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2 className="wow bounceIn" data-wow-offset="50" data-wow-delay="0.3s">ABOUT <span>ME</span></h2>
                        </Col>
                        <Col md={{ size: 6, offset: 3 }} sm="12" xs="12" className="wow fadeIn center" data-wow-offset="50" data-wow-delay="0.6s">
                            <div className="team-wrapper">
                                <Img src={require('../images/team-img1.jpg')} className="img-responsive" width="100%" alt="team img 1" loader={<Spinner />}/>
                                <div className="team-des">
                                    <h4><span>Hi Friend</span></h4>
                                    <p>I'm a senior full stack software developer with over 10 years' experience in C# and .NET, currently based in Manchester, UK. I hold a PhD in Computational Biology, with a focus on Image Processing and Computer Vision. My previous projects range from game design in Unity 3D to developing visualisation software for nuclear laboratories. You can view some of my favourite projects in my <a href="#portfolio">portfolio</a>.<br /><br />Need something built or simply want to have chat? Reach out to me on social media or send me an <a href="#contact">email.</a></p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="service">
                <Container>
                    <Row className="t2">
                        <Col md="12">
                            <h2 className="wow bounceIn" data-wow-offset="50" data-wow-delay="0.3s">MY <span>SPECIALITIES</span></h2>
                        </Col>
                        <Col md="4" className="wow fadeIn" data-wow-offset="50" data-wow-delay="0.6s">
                            <div className="fa">
                                <FontAwesomeIcon icon={faLaptop}/>
                            </div>
                            <h4>C#</h4>
                            <p>Whether you want a beautiful desktop application or complex cloud based microservices, nothing quite beats the power and flexibility of C#. I've been working with C# and the latest tech for over 11 years now, and created standalone desktop applications, enterprise scale microservices, and everything inbetween.</p>
                        </Col>
                        <Col md="4" className="active wow fadeIn" data-wow-offset="50" data-wow-delay="0.9s">
                            <div className="fa">
                                <FontAwesomeIcon icon={faEye}/>
                            </div>
                            <h4>Image Processing & Computer Vision</h4>
                            <p>Not quite satisfied with front end development, I embarked on a PhD. After 3 years, I emerged with a doctorate in Image Processing and Computer Vision. Utilising several Deep Learning frameworks and Computer Vision libraries, I've delivered cutting edge technology into client Applications.</p>
                        </Col>
                        <Col md="4" className="wow fadeIn" data-wow-offset="50" data-wow-delay="0.6s">
                            <div className="fa">
                                <FontAwesomeIcon icon={faUserShield}/>
                            </div>
                            <h4>Security</h4>
                            <p>I've created enterprise class authentication systems, tested to the highest standards. From Active Directory to SSO, and even the traditional Username and Password, I've made sure thousands of users can securely use an application.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Portfolio/>
            <ContactMe/>
            </div>
        </div>
);

export default connect()(Home);
