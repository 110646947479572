import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import './custom.css'
import Nas from './components/pages/nas/nas';
import Mwa from './components/pages/mwa/mwa';
import NotFound from './components/pages/NotFound';
import Projects from './components/pages/projects/projects';
import SkyControl from './components/pages/skycontrol/skycontrol';
import BraviaControl from './components/pages/braviacontrol/braviacontrol';
import MonitorInfo from './components/pages/monitorinfo/monitorinfo';
import AudioInfo from './components/pages/audioinfo/audioinfo';
import ChromeControl from './components/pages/chromecontrol/chromecontrol';
import BrowserViewer from './components/pages/browserviewer/browserviewer';
import Terms from './components/pages/browserviewer/terms';

import * as ReactGA from "react-ga";

ReactGA.initialize('UA-41788510-3');

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/nas' component={Nas} />
            <Route path='/mwa' component={Mwa} />
            <Route path='/sky-control' component={SkyControl} />
            <Route path='/sony-bravia-control' component={BraviaControl} />
            <Route path='/monitor-info' component={MonitorInfo} />
            <Route path='/audio-devices' component={AudioInfo} />
            <Route path='/projects' component={Projects} />
            <Route path='/chrome-control' component={ChromeControl} />
            <Route path='/browser-viewer/terms' component={Terms} />
            <Route path='/browser-viewer' component={BrowserViewer} />
            <Route component={NotFound} />
        </Switch>
    </Layout>
);
