import * as React from 'react';
import { Row, Col, Container } from 'reactstrap';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as ReactGA from "react-ga";

export default class HeaderBar extends React.Component {

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    public render() {        
        return (
            <div id="header">
                <header>
                    <Container>
                        <Row>
                            <Col md="6" sm="6" xs="12">
                                <FontAwesomeIcon color="#28a7e9" icon={faEnvelope} /><span> Email</span><a href="#">brett@bretthewitt.net</a>
                            </Col>
                            <Col md="6" sm="6" xs="12">
                                <ul className="social-icon">
                                    <li><span>Meet me on</span></li>
                                    <li><a href="https://fb.me/drbretthewitt" className="fa facebook"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                                    <li><a href="https://twitter.com/hewitt_brett" className="twitter"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                    <li><a href="https://www.linkedin.com/in/dr-brett-hewitt-5587167b/" className="linkedin"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </header>
            </div>
        );
    }
}