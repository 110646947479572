import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

const NotFound = () => (
    <div className="cc">
        <section id="contact">
            <Container className="maincontainer">
                <Row>
                    <Col md="12">
                        <h2 className="wow bounceIn" data-wow-offset="50" data-wow-delay="0.3s">Page not found</h2>
                    </Col>
                </Row>                         
            </Container>
        </section>
    </div>
);

export default connect()(NotFound);
