import * as React from 'react';
import { Row, Col, Container, Spinner } from 'reactstrap';
import './ContactMe.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

interface IState {
    name: string,
    email: string,
    message: string,
    error: string,
    messageSending: boolean,
    messageSent: boolean,
}

export default class ContactMe extends React.Component<{}, IState> {
    public state = {
        name: '',
        email: '',
        message: '',
        error: '',
        messageSending: false,
        messageSent: false
    };

    constructor(props: any) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.getCookie = this.getCookie.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
    }

    private handleSubmit = (event: any) => {
        event.preventDefault();

        this.setState({
            messageSending: true
        });

        let xsrfToken = this.getCookie('XSRF-TOKEN');   //*get AntiforgeryToken from Cookies
        if (xsrfToken == null) { alert("AntiforgeryToken is missing") };

        const data = {
            Name: this.state.name,
            Email: this.state.email,
            Message: this.state.message,
        };

        fetch("email/send", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': "application/json, text/plain, */*",
                'Content-Type': "application/json;charset=utf-8",
                'X-XSRF-TOKEN': xsrfToken
            }
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (!data["success"]) {
                    switch (data["errorCode"]) {
                        default:
                            this.setState({
                                error: "Something went wrong",
                                messageSent: true
                            });
                    }
                }
                else {
                    this.setState({
                        messageSent: true
                    });
                }
            });
    }

    private getCookie = (name: string) => {
        let cookieValue = "";
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();

                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }

        return cookieValue;
    }

    private onNameChange(e: any) {
        this.setState({
            name: e.target.value
        });
    }

    private onEmailChange(e: any) {
        this.setState({
            email: e.target.value
        });
    }

    private onMessageChange(e: any) {
        this.setState({
            message: e.target.value
        });
    }

    private renderContactSection() {
        if (this.state.messageSent) {
            if (!this.state.error) {
                return this.renderSuccessMessage();
            }
            else {
                return this.renderError();
            }
        }
        else if (this.state.messageSending) {
            return this.renderSending();
        }
        else {
            return this.renderForm();
        }
    }

    private renderForm() {
        return (
            <div id="eform">
                <form id="sendEmail" onSubmit={this.handleSubmit}>
                    <label>NAME</label>
                    <input name="fullname" type="text" className="form-control" id="fullname" ref="nameInput" onChange={this.onNameChange} />

                    <label>EMAIL</label>
                    <input name="email" type="email" className="form-control" id="email" ref="emailInput" onChange={this.onEmailChange} />

                    <label>MESSAGE</label>
                    <textarea name="textmsg" form="sendEmail" rows={4} className="form-control" id="textmsg" ref="messageInput" onChange={this.onMessageChange}></textarea>

                    <input type="submit" className="form-control" id="submit" />
                </form>
            </div>
        );        
    }

    private renderError() {
        return (
            <div id="error_message">
                {this.state.error}
            </div>
        );
    }

    private renderSuccessMessage() {
        return (
            <div id="successMessage">
                <h3>Your message has been sent and we will get back to you as quickly possible.</h3>
            </div>
            );
    }

    private renderSending() {
        return (
            <div id="successMessage">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }

    public render() {
        
        return (
            <section id="contact">
                <Container>
                    <Row className="t2">
                        <Col md="12">
                            <h2 className="wow bounceIn" data-wow-offset="50" data-wow-delay="0.1s">CONTACT <span>ME</span></h2>
                        </Col>
                        <Col md="6" sm="6" xs="12" className="wow fadeInLeft" data-wow-offset="50" data-wow-delay="0.9s">
                            {this.renderContactSection()}
                        </Col>
                        <Col md="6" sm="6" xs="12" className="wow fadeInRight" data-wow-offset="50" data-wow-delay="0.6s">
                            <address>
                                <p className="address-title">MY DETAILS</p>
                                <p><FontAwesomeIcon icon={faEnvelope} className="fa" /><span className="ml-2">brett@bretthewitt.net</span></p>
                                <p><FontAwesomeIcon icon={faMapMarkerAlt} className="fa" /><span className="ml-2"> Manchester, UK</span></p>
                            </address>
                            <ul className="social-icon">
                                <li><h4>I AM SOCIAL</h4></li>
                                <li><a href="https://fb.me/drbretthewitt"><FontAwesomeIcon icon={faFacebookF} className="fa facebook" /></a></li>
                                <li><a href="https://twitter.com/hewitt_brett"><FontAwesomeIcon icon={faTwitter} className="fa twitter" /></a></li>
                                <li><a href="https://www.linkedin.com/in/dr-brett-hewitt-5587167b/"><FontAwesomeIcon icon={faLinkedinIn} className="fa linkedin" /></a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}